import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('@/views/HomePage.vue'),
    meta: { title: '凌凌七(重庆)网络科技有限公司' }
  }
];

const router = createRouter({ history: createWebHistory(), routes });
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('authToken');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ path: '/admin/login' });
  } else {
    next();
  }

  if (to.meta.title) {
    window.document.title = to.meta.title;
  }
  else {
    window.document.title = '凌凌七(重庆)网络科技有限公司';
  }
});

export default router;
